<template>
    <div class="sider-bar-inner-sections fast-access-list">
        <span v-for="oracle in oracles" :key="oracle.id">
            <router-link :to="{ name: 'oracle', params: { id: oracle.id } }"> - {{ oracle.name }} </router-link>
        </span>
    </div>
</template>

<script lang="ts">
import { FastAccessNetworkItem } from "@/api/oracle-config/definitions";
import { PropType, defineComponent } from "vue";
import Sortable from "sortablejs";

export default defineComponent({
    name: "FastAccessList",
    events: ['swap'],
    props: {
        oracles: Array as PropType<FastAccessNetworkItem[]>,
    },
    setup: function () {
        return {
            sortable: null as Sortable,
        };
    },
    methods: {
        onSortableUpdate: function (ev: Sortable.SortableEvent) {
            this.$emit("swap", ev.oldIndex, ev.newIndex);
        },

        refreshSortable: function () {
            if (this.sortable) {
                this.sortable.destroy();
                this.sortable = null;
            }

            this.sortable = new Sortable(this.$el, {
                onUpdate: this.onSortableUpdate.bind(this),
            });
        },
    },

    mounted: function () {
        this.refreshSortable();
    },
    beforeUnmount: function () {
        if (this.sortable) {
            this.sortable.destroy();
            this.sortable = null;
        }
    },
});
</script>

<style></style>
