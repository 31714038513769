<template>
    <transition
        enter-active-class="enter-active"
        leave-active-class="leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
    >
        <slot v-if="display"></slot>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    name: "TransitionCollapseHeight",
    props: {
        display: Boolean,
    },
    methods: {
        beforeEnter: function (element: HTMLElement) {
            requestAnimationFrame(function () {
                if (!element.style.height) {
                    element.style.height = "0px";
                }

                element.style.display = null;
            });
        },
        enter: function (element: HTMLElement) {
            requestAnimationFrame(function () {
                requestAnimationFrame(function () {
                    element.style.height = element.scrollHeight + "px";
                });
            });
        },

        afterEnter: function (element: HTMLElement) {
            element.style.height = null;
        },

        beforeLeave: function (element: HTMLElement) {
            requestAnimationFrame(function () {
                if (!element.style.height) {
                    element.style.height = element.offsetHeight + "px";
                }
            });
        },

        leave: function (element: HTMLElement) {
            requestAnimationFrame(function () {
                requestAnimationFrame(function () {
                    element.style.height = "0px";
                });
            });
        },

        afterLeave: function (element: HTMLElement) {
            element.style.height = null;
        },
    },
    mounted: function () {},
    beforeUnmount: function () {},
});
</script>

<style></style>
