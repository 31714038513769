// Current node controller

"use strict";

import { Request } from "@asanrom/request-browser";
import { AuthController } from "./auth";
import { AppEvents } from "./app-events";
import { Timeouts } from "@/utils/timeout";
import { CurrentNetworkController } from "./current-network";
import { OracleNodeStatus } from "@/api/oracle-monitoring/definitions";
import { ApiNodeStatus } from "@/api/oracle-monitoring/api-group-node-status";

const LOAD_REQUEST_ID = "current-node-load";
const UPDATE_EVENT_NAME = "current-node-changed";

export class CurrentNodeController {
    public static NetworkId: string = "";

    public static NodeId: string = "";

    public static NodeData: OracleNodeStatus = null;

    public static Loading = true;

    public static Initialize() {
        AppEvents.AddEventListener("auth-status-changed", CurrentNodeController.Load);

        CurrentNodeController.Load();
    }

    public static SetNode(networkId: string, nodeId: string, forceLoad?: boolean) {
        CurrentNetworkController.SetNetwork(networkId, forceLoad);
        if (CurrentNodeController.NetworkId !== networkId || CurrentNodeController.NodeId !== nodeId || forceLoad) {
            CurrentNodeController.NetworkId = networkId;
            CurrentNodeController.NodeId = nodeId;
            CurrentNodeController.Load();
        }
    }

    public static Load() {
        Timeouts.Abort(LOAD_REQUEST_ID);
        Request.Abort(LOAD_REQUEST_ID);

        if (!AuthController.isAuthenticated() || !CurrentNodeController.NetworkId || !CurrentNodeController.NodeId) {
            CurrentNodeController.Loading = false;
            CurrentNodeController.NodeData = null;
            AppEvents.Emit(UPDATE_EVENT_NAME);
            return;
        }

        CurrentNodeController.Loading = true;
        CurrentNodeController.NodeData = null;
        AppEvents.Emit(UPDATE_EVENT_NAME);

        Request.Pending(LOAD_REQUEST_ID, ApiNodeStatus.GetNode(CurrentNodeController.NetworkId, CurrentNodeController.NodeId))
            .onSuccess((response) => {
                CurrentNodeController.Loading = false;
                CurrentNodeController.NodeData = response;

                AppEvents.Emit(UPDATE_EVENT_NAME);
            })
            .onRequestError((err, handleErr) => {
                handleErr(err, {
                    unauthorized: () => {
                        CurrentNodeController.Loading = false;
                        CurrentNodeController.NodeData = null;
                        AppEvents.Emit(UPDATE_EVENT_NAME);
                    },
                    notFound: () => {
                        CurrentNodeController.Loading = false;
                        CurrentNodeController.NodeData = null;
                        AppEvents.Emit(UPDATE_EVENT_NAME);
                    },
                    notFoundNetworkNotFound: () => {
                        CurrentNodeController.Loading = false;
                        CurrentNodeController.NodeData = null;
                        AppEvents.Emit(UPDATE_EVENT_NAME);
                    },
                    notFoundNodeNotFound: () => {
                        CurrentNodeController.Loading = false;
                        CurrentNodeController.NodeData = null;
                        AppEvents.Emit(UPDATE_EVENT_NAME);
                    },
                    temporalError: () => {
                        // Retry
                        Timeouts.Set(LOAD_REQUEST_ID, 1500, CurrentNodeController.Load);
                    },
                });
            })
            .onUnexpectedError((err) => {
                console.error(err);
                // Retry
                Timeouts.Set(LOAD_REQUEST_ID, 1500, CurrentNodeController.Load);
            });
    }
}

CurrentNodeController.Initialize();
