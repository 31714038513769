// URL utils

"use strict";

/**
 * Generates query string for API
 * @param params The parameters
 * @returns The query string
 */
export function generateURIQuery(params: any): string {
    if (typeof params !== "object" || !params) {
        return "";
    }

    const keys = Object.keys(params);

    if (keys.length === 0) {
        return "";
    }

    let result = "";

    for (const key of keys) {
        if (params[key] === undefined || params[key] === null) {
            continue;
        }

        if (result !== "") {
            result += "&";
        } else {
            result += "?";
        }

        result += encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
    }

    return result;
}
