// Fast access networks controller

"use strict";

import { ApiFastAccess } from "@/api/oracle-config/api-group-fast-access";
import { FastAccessNetworkItem } from "@/api/oracle-config/definitions";
import { Request } from "@asanrom/request-browser";
import { AuthController } from "./auth";
import { AppEvents } from "./app-events";
import { Timeouts } from "@/utils/timeout";

const LOAD_REQUEST_ID = "fast-access-networks-ctr-load";

export class FastAccessNetworkController {
    public static Networks: FastAccessNetworkItem[] = [];

    public static NetworksSet: Set<string> = new Set();

    public static Version = 0;

    public static Loading = true;

    public static Initialize() {
        AppEvents.AddEventListener("auth-status-changed", FastAccessNetworkController.Load);
        AppEvents.AddEventListener("must-reload-networks-lists", FastAccessNetworkController.Load);

        FastAccessNetworkController.Load();
    }

    public static Load() {
        Timeouts.Abort(LOAD_REQUEST_ID);
        Request.Abort(LOAD_REQUEST_ID);

        if (!AuthController.isAuthenticated()) {
            FastAccessNetworkController.Loading = false;
            FastAccessNetworkController.Networks = [];
            FastAccessNetworkController.NetworksSet.clear();
            AppEvents.Emit("fast-access-networks-changed");
            return;
        }

        FastAccessNetworkController.Loading = true;

        Request.Pending(LOAD_REQUEST_ID, ApiFastAccess.GetNetworks())
            .onSuccess((response) => {
                FastAccessNetworkController.Loading = false;
                FastAccessNetworkController.Networks = response;
                FastAccessNetworkController.NetworksSet.clear();

                for (const n of FastAccessNetworkController.Networks) {
                    FastAccessNetworkController.NetworksSet.add(n.id);
                }

                FastAccessNetworkController.Version++;

                AppEvents.Emit("fast-access-networks-changed");
            })
            .onRequestError((err, handleErr) => {
                handleErr(err, {
                    unauthorized: () => {
                        FastAccessNetworkController.Loading = false;
                        FastAccessNetworkController.Networks = [];
                        FastAccessNetworkController.NetworksSet.clear();
                        AppEvents.Emit("fast-access-networks-changed");
                    },
                    temporalError: () => {
                        // Retry
                        Timeouts.Set(LOAD_REQUEST_ID, 1500, FastAccessNetworkController.Load);
                    },
                });
            })
            .onUnexpectedError((err) => {
                console.error(err);
                // Retry
                Timeouts.Set(LOAD_REQUEST_ID, 1500, FastAccessNetworkController.Load);
            });
    }
}

FastAccessNetworkController.Initialize();
