<template>
    <div class="side-bar" :class="{ hidden: !expanded }">
        <div class="side-bar-logo-td">
            <div class="logo-container" @click="goHome">
                <img class="logo" src="/img/logo.png" />
            </div>
        </div>

        <div class="side-bar-body">
            <div v-for="link in links" :key="link.name">
                <router-link class="side-bar-option" :class="{ selected: page === link.name }" :title="link.text" :to="link.to">
                    <div class="side-bar-option-icon"><i :class="link.icon"></i></div>
                    <div class="side-bar-option-text">{{ link.text }}</div>
                </router-link>
            </div>

            <div class="side-bar-separator"></div>
            <div v-if="isAuthenticated && fastAccessNetworks.length > 0" class="side-bar-section">
                <div class="side-bar-section-container">
                    <div class="side-bar-option-icon">
                        <i class="fas fa-star"></i><span>{{ $t("Fast access") }}</span>
                    </div>
                    <div class="side-bar-buttons">
                        <i
                            :class="`fas ${fastAccessOraclesVisible ? 'fa-chevron-up' : 'fa-chevron-down'}`"
                            @click="toggleFastAccessOraclesVisible"
                        ></i>
                        <i class="fas fa-plus" @click="goToAddOracle"></i>
                    </div>
                </div>
                <TransitionCollapseHeight :display="fastAccessOraclesVisible">
                    <FastAccessList :oracles="fastAccessNetworks" @swap="onFastAccessSwap"></FastAccessList>
                </TransitionCollapseHeight>
            </div>

            <div v-if="isAuthenticated" class="side-bar-section">
                <div class="side-bar-section-container">
                    <div class="side-bar-option-icon">
                        <i class="fas fa-note-sticky"></i><span>{{ $t("Recently") }}</span>
                    </div>
                    <div class="side-bar-buttons">
                        <i
                            :class="`fas ${recentOraclesVisible ? 'fa-chevron-up' : 'fa-chevron-down'}`"
                            @click="toggleRecentOraclesVisible"
                        ></i>
                        <i class="fas fa-plus" @click="goToAddOracle"></i>
                    </div>
                </div>
                <TransitionCollapseHeight :display="recentOraclesVisible">
                    <div class="sider-bar-inner-sections">
                        <span v-for="oracle in recentOracles" :key="oracle.id">
                            <router-link :to="{ name: 'oracle', params: { id: oracle.id } }"> - {{ oracle.name }} </router-link>
                        </span>
                        <span v-if="recentOraclesLoading && recentOracles.length === 0">
                            <i class="fa fa-spinner fa-spin"></i> {{ $t("Loading") }}...
                        </span>
                        <span v-if="!recentOraclesLoading && recentOracles.length === 0">
                            {{ $t("No recent networks created") }}
                        </span>
                    </div>
                </TransitionCollapseHeight>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import { getUniqueStringId } from "@/utils/unique-id";
import { ApiOracleList } from "@/api/oracle-config/api-group-oracle-list";
import { Request } from "@asanrom/request-browser";
import { Timeouts } from "@/utils/timeout";
import { OracleNetworkItem } from "@/api/oracle-config/definitions";
import { AuthController } from "@/control/auth";
import TransitionCollapseHeight from "../utils/TransitionCollapseHeight.vue";
import { FastAccessNetworkController } from "@/control/fast-access-networks";
import FastAccessList from "../utils/FastAccessList.vue";
import { ApiFastAccess } from "@/api/oracle-config/api-group-fast-access";

const RECENT_ORACLE_NETWORKS_LIMIT = 10;

export default defineComponent({
    components: { TransitionCollapseHeight, FastAccessList },
    name: "MenuSideBar",
    emits: ["update:expanded"],
    props: {
        expanded: Boolean,
    },
    setup(props) {
        return {
            expandedStatus: useVModel(props, "expanded"),
            loadRecentNetworksRequestId: getUniqueStringId(),
            updateFastAccessRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            page: "",

            isAuthenticated: AuthController.isAuthenticated(),

            recentOracles: [] as OracleNetworkItem[],
            recentOraclesVisible: true,
            recentOraclesLoading: true,

            fastAccessNetworks: FastAccessNetworkController.Networks.slice(),
            fastAccessOraclesVisible: true,
        };
    },
    methods: {
        loadRecentNetworks: function () {
            Timeouts.Abort(this.loadRecentNetworksRequestId);
            Request.Abort(this.loadRecentNetworksRequestId);

            if (!AuthController.isAuthenticated()) {
                return;
            }

            this.recentOraclesLoading = true;

            Request.Pending(this.loadRecentNetworksRequestId, ApiOracleList.GetOracleNetworks({ limit: RECENT_ORACLE_NETWORKS_LIMIT }))
                .onSuccess((response) => {
                    this.recentOraclesLoading = false;
                    this.recentOracles = response;
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.recentOraclesLoading = false;
                            this.recentOracles = [];
                        },
                        temporalError: () => {
                            Timeouts.Set(this.loadRecentNetworksRequestId, 1500, this.loadRecentNetworks.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    Timeouts.Set(this.loadRecentNetworksRequestId, 1500, this.loadRecentNetworks.bind(this));
                });
        },

        toggleRecentOraclesVisible: function () {
            this.recentOraclesVisible = !this.recentOraclesVisible;
        },

        toggleFastAccessOraclesVisible: function () {
            this.fastAccessOraclesVisible = !this.fastAccessOraclesVisible;
        },

        updatePage: function () {
            this.page = this.$route ? (this.$route.name as string) : "";
        },
        onAuthChanged: function () {
            this.isAuthenticated = AuthController.isAuthenticated();
            this.loadRecentNetworks();
        },

        goHome: function () {
            this.$router.push({ name: "home" });
        },

        goToAddOracle: function () {
            this.$router.push({ name: "add-oracle" });
        },

        onFastAccessUpdated: function () {
            this.fastAccessNetworks = FastAccessNetworkController.Networks.slice();
        },

        onFastAccessSwap: function (oldIndex: number, newIndex: number) {
            this.fastAccessNetworks.splice(newIndex, 0, this.fastAccessNetworks.splice(oldIndex, 1)[0]);

            // Call API
            const orderedIds = this.fastAccessNetworks.map((f) => f.id);

            Request.Pending(this.updateFastAccessRequestId, ApiFastAccess.SetOrder({ sortedList: orderedIds }));
        },
    },
    computed: {
        links() {
            return [
                { name: "Dashboard", text: `${this.$t("Dashboard")}`, to: { name: "home" }, icon: "fas fa-home" },
                { name: "Oracles", text: `${this.$t("Oracles")}`, to: { name: "oracles" }, icon: "fas fa-list" },
                // { name: "Security", text: `${this.$t("Security")}`, to: { name: "security" }, icon: "fas fa-shield" },
                {
                    name: "Oracle smart contracts",
                    text: `${this.$t("Oracle smart contracts")}`,
                    to: { name: "smart-contracts" },
                    icon: "fas fa-book",
                },
                // {
                //     name: "Support & Help Center",
                //     text: `${this.$t("Support & Help Center")}`,
                //     to: { name: "help" },
                //     icon: "fas fa-question",
                // },
            ];
        },
    },
    mounted: function () {
        this.loadRecentNetworks();
        this.updatePage();
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        this.$listenOnAppEvent("fast-access-networks-changed", this.onFastAccessUpdated.bind(this));
        this.$listenOnAppEvent("must-reload-networks-lists", this.loadRecentNetworks.bind(this));
    },
    watch: {
        $route: function () {
            this.updatePage();
        },
    },
    beforeUnmount: function () {
        Timeouts.Abort(this.loadRecentNetworksRequestId);
        Request.Abort(this.loadRecentNetworksRequestId);
    },
});
</script>

<style>
.side-bar-section-container {
    gap: 5px;
    display: flex;
    color: rgba(255, 255, 255, 1);
    position: relative;
}
.side-bar-body .router-link-active {
    background: var(--side-bar-bg-option-selected);
}

.side-bar-body a:hover {
    background: var(--side-bar-bg-option-selected);
}

.side-bar-section-container .side-bar-option-icon {
    width: 100%;
    justify-content: left;
}

.side-bar-section-container .side-bar-option-icon i {
    width: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    padding: 0 1rem 0 1rem;
    position: relative;
    color: rgba(255, 255, 255, 1);
}
.side-bar-section-container .side-bar-buttons {
    display: flex;
    right: 20px;
    position: absolute;
    top: 40%;
    gap: 16px;
}
.side-bar-section-container .side-bar-buttons i {
    cursor: pointer;
}
.sider-bar-inner-sections {
    padding: 5px 35px;
    line-height: 30px;
}
.sider-bar-inner-sections span:hover {
    background: var(--side-bar-bg-option-selected);
}
.sider-bar-inner-sections a {
    color: #fff;
    text-decoration: none;
    display: block;
}
.sider-bar-inner-sections a:hover {
    background: transparent !important;
}
.light-theme .sider-bar-inner-sections {
    color: #fff;
}
.sider-bar-inner-sections span {
    display: block;
    margin: 4px 0;
}

.side-bar .logo-container {
    cursor: pointer;
}

@media (max-width: 580px) {
    .side-bar {
        width: 260px !important;
    }
    .side-bar .logo-container {
        height: 100px;
    }
    .side-bar-body {
        padding-top: 0;
    }
    .side-bar .side-bar-option-text,
    .side-bar .side-bar-option-icon {
        font-size: 16px;
    }
}

.sider-bar-inner-sections {
    transition: height 0.2s ease-in-out;
    overflow: hidden;
}
</style>
