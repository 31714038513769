// Current network controller

"use strict";

import { OracleNetwork } from "@/api/oracle-config/definitions";
import { Request } from "@asanrom/request-browser";
import { AuthController } from "./auth";
import { AppEvents } from "./app-events";
import { Timeouts } from "@/utils/timeout";
import { ApiOracle } from "@/api/oracle-config/api-group-oracle";

const LOAD_REQUEST_ID = "current-network-load";

export class CurrentNetworkController {
    public static NetworkId: string = "";

    public static NetworkData: OracleNetwork = null;

    public static Loading = true;

    public static Initialize() {
        AppEvents.AddEventListener("auth-status-changed", CurrentNetworkController.Load);

        CurrentNetworkController.Load();
    }

    public static SetNetwork(networkId: string, forceLoad?: boolean) {
        if (CurrentNetworkController.NetworkId !== networkId || forceLoad) {
            CurrentNetworkController.NetworkId = networkId;
            CurrentNetworkController.Load();
        }
    }

    public static Load() {
        Timeouts.Abort(LOAD_REQUEST_ID);
        Request.Abort(LOAD_REQUEST_ID);

        if (!AuthController.isAuthenticated() || !CurrentNetworkController.NetworkId) {
            CurrentNetworkController.Loading = false;
            CurrentNetworkController.NetworkData = null;
            AppEvents.Emit("current-network-changed");
            return;
        }

        CurrentNetworkController.Loading = true;
        CurrentNetworkController.NetworkData = null;
        AppEvents.Emit("current-network-changed");

        Request.Pending(LOAD_REQUEST_ID, ApiOracle.GetOracleNetwork(CurrentNetworkController.NetworkId))
            .onSuccess((response) => {
                CurrentNetworkController.Loading = false;
                CurrentNetworkController.NetworkData = response;

                AppEvents.Emit("current-network-changed");
            })
            .onRequestError((err, handleErr) => {
                handleErr(err, {
                    unauthorized: () => {
                        CurrentNetworkController.Loading = false;
                        CurrentNetworkController.NetworkData = null;
                        AppEvents.Emit("current-network-changed");
                    },
                    notFound: () => {
                        CurrentNetworkController.Loading = false;
                        CurrentNetworkController.NetworkData = null;
                        AppEvents.Emit("current-network-changed");
                    },
                    temporalError: () => {
                        // Retry
                        Timeouts.Set(LOAD_REQUEST_ID, 1500, CurrentNetworkController.Load);
                    },
                });
            })
            .onUnexpectedError((err) => {
                console.error(err);
                // Retry
                Timeouts.Set(LOAD_REQUEST_ID, 1500, CurrentNetworkController.Load);
            });
    }
}

CurrentNetworkController.Initialize();
