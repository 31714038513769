// API bindings: node_status (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler, CommonErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { OracleNodeListItem, OracleNodesCount, OracleNodeStatus, NodeLocationReportBody, NodeConnectionsReportBody, NodeLinkingToken, NodeTokenRefreshBody } from "./definitions";

export class ApiNodeStatus {
    /**
     * Method: GET
     * Path: /oracle/{network}/nodes
     * Gets the list of nodes of a network.
     * Most active modes first.
     * Paginated by lastTimeActive.
     * @param network Network ID
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetNodes(network: string, queryParams: GetNodesQueryParameters): RequestParams<OracleNodeListItem[], GetNodesErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/oracle/${encodeURIComponent(network)}/nodes` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /oracle/{network}/nodes_count
     * Counts the number of nodes of a network
     * @param network Network ID
     * @returns The request parameters
     */
    public static CountNodes(network: string): RequestParams<OracleNodesCount, CountNodesErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/oracle/${encodeURIComponent(network)}/nodes_count`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /oracle/{network}/nodes/{id}
     * Gets node status
     * @param network Network ID
     * @param id Node ID
     * @returns The request parameters
     */
    public static GetNode(network: string, id: string): RequestParams<OracleNodeStatus, GetNodeErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/oracle/${encodeURIComponent(network)}/nodes/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "NODE_NOT_FOUND", handler.notFoundNodeNotFound)
                    .add(404, "NETWORK_NOT_FOUND", handler.notFoundNetworkNotFound)
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /oracle/{network}/nodes/{id}
     * Deletes inactive node
     * @param network Network ID
     * @param id Node ID
     * @returns The request parameters
     */
    public static DeleteInactiveNode(network: string, id: string): RequestParams<void, DeleteInactiveNodeErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/oracle/${encodeURIComponent(network)}/nodes/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "NODE_NOT_FOUND", handler.notFoundNodeNotFound)
                    .add(404, "NETWORK_NOT_FOUND", handler.notFoundNetworkNotFound)
                    .add(404, "*", handler.notFound)
                    .add(400, "NODE_IS_ACTIVE", handler.badRequestNodeIsActive)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /oracle/{network}/nodes/{id}/reports/location
     * Reports node location
     * @param network Network ID
     * @param id Node ID
     * @param body Body parameters
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static ReportLocation(network: string, id: string, body: NodeLocationReportBody, headerParams: ReportLocationHeaderParameters): RequestParams<void, ReportLocationErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/oracle/${encodeURIComponent(network)}/nodes/${encodeURIComponent(id)}/reports/location`),
            json: body,
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "NODE_NOT_FOUND", handler.notFoundNodeNotFound)
                    .add(404, "NETWORK_NOT_FOUND", handler.notFoundNetworkNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "INVALID_LNG", handler.badRequestInvalidLng)
                    .add(400, "INVALID_LAT", handler.badRequestInvalidLat)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /oracle/{network}/nodes/{id}/reports/connections
     * Reports node connections
     * @param network Network ID
     * @param id Node ID
     * @param body Body parameters
     * @param headerParams Header parameters
     * @returns The request parameters
     */
    public static ReportConnections(network: string, id: string, body: NodeConnectionsReportBody, headerParams: ReportConnectionsHeaderParameters): RequestParams<void, ReportConnectionsErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/oracle/${encodeURIComponent(network)}/nodes/${encodeURIComponent(id)}/reports/connections`),
            json: body,
            headers: headerParams as any,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "NODE_NOT_FOUND", handler.notFoundNodeNotFound)
                    .add(404, "NETWORK_NOT_FOUND", handler.notFoundNetworkNotFound)
                    .add(404, "*", handler.notFound)
                    .add(403, "*", handler.forbidden)
                    .add(400, "*", handler.badRequest)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /oracle/{network}/nodes/{id}/token
     * Generates a new linking token for the node
     * @param network Network ID
     * @param id Node ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static RefreshNodeToken(network: string, id: string, body: NodeTokenRefreshBody): RequestParams<NodeLinkingToken, RefreshNodeTokenErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/oracle/${encodeURIComponent(network)}/nodes/${encodeURIComponent(id)}/token`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "NODE_NOT_FOUND", handler.notFoundNodeNotFound)
                    .add(404, "NETWORK_NOT_FOUND", handler.notFoundNetworkNotFound)
                    .add(404, "*", handler.notFound)
                    .add(400, "INVALID_NAME", handler.badRequestInvalidName)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for GetNodes
 */
export type GetNodesErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Query parameters for GetNodes
 */
export interface GetNodesQueryParameters {
    /**
     * Earliest last active date the client has, in order to request the next page
     */
    earliest_date?: number;

    /**
     * Max number of results per page. hard limited to 256.
     */
    limit?: number;
}

/**
 * Error handler for CountNodes
 */
export type CountNodesErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for GetNode
 */
export type GetNodeErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Network not found
     */
    notFoundNetworkNotFound: () => void;

    /**
     * Node not found
     */
    notFoundNodeNotFound: () => void;
};

/**
 * Error handler for DeleteInactiveNode
 */
export type DeleteInactiveNodeErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * The node is not inactive
     */
    badRequestNodeIsActive: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Network not found
     */
    notFoundNetworkNotFound: () => void;

    /**
     * Node not found
     */
    notFoundNodeNotFound: () => void;
};

/**
 * Error handler for ReportLocation
 */
export type ReportLocationErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Invalid latitude value
     */
    badRequestInvalidLat: () => void;

    /**
     * Invalid longitude value
     */
    badRequestInvalidLng: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Network not found
     */
    notFoundNetworkNotFound: () => void;

    /**
     * Node not found
     */
    notFoundNodeNotFound: () => void;
};

/**
 * Header parameters for ReportLocation
 */
export interface ReportLocationHeaderParameters {
    /**
     * Authentication token
     */
    "x-oracle-auth": string;
}

/**
 * Error handler for ReportConnections
 */
export type ReportConnectionsErrorHandler = CommonErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * General handler for status = 403
     */
    forbidden: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Network not found
     */
    notFoundNetworkNotFound: () => void;

    /**
     * Node not found
     */
    notFoundNodeNotFound: () => void;
};

/**
 * Header parameters for ReportConnections
 */
export interface ReportConnectionsHeaderParameters {
    /**
     * Authentication token
     */
    "x-oracle-auth": string;
}

/**
 * Error handler for RefreshNodeToken
 */
export type RefreshNodeTokenErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Invalid node name
     */
    badRequestInvalidName: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;

    /**
     * Network not found
     */
    notFoundNetworkNotFound: () => void;

    /**
     * Node not found
     */
    notFoundNodeNotFound: () => void;
};

