// API bindings: oracle_list (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl, generateURIQuery } from "./utils";
import { OracleNetworkGlobalCountResult, OracleNetworkItem } from "./definitions";

export class ApiOracleList {
    /**
     * Method: GET
     * Path: /oracles_count
     * Counts oracles and total nodes.
     * @returns The request parameters
     */
    public static CountOracleNetworks(): RequestParams<OracleNetworkGlobalCountResult, CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/oracles_count`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: GET
     * Path: /oracles
     * Lists oracles.
     * Sorted in descendant order by last modified date.
     * Paginated using the last modified date as filter.
     * @param queryParams Query parameters
     * @returns The request parameters
     */
    public static GetOracleNetworks(queryParams: GetOracleNetworksQueryParameters): RequestParams<OracleNetworkItem[], CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/oracles` + generateURIQuery(queryParams)),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Query parameters for GetOracleNetworks
 */
export interface GetOracleNetworksQueryParameters {
    /**
     * Earliest last modified date the client has, in order to request the next page
     */
    earliest_date?: number;

    /**
     * Max number of results per page. hard limited to 256.
     */
    limit?: number;
}

