// API bindings: oracle (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl } from "./utils";
import { OracleNetwork, OracleNetworkCreateResponse, OracleNetworkCreateBody, OracleNetworkDetailsChangeBody, OracleConfiguration } from "./definitions";

export class ApiOracle {
    /**
     * Method: GET
     * Path: /oracle/{id}
     * Gets oracle details and configuration
     * @param id Network ID
     * @returns The request parameters
     */
    public static GetOracleNetwork(id: string): RequestParams<OracleNetwork, GetOracleNetworkErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/oracle/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /oracle/{id}
     * Deletes oracle network
     * @param id Network ID
     * @returns The request parameters
     */
    public static DeleteOracleNetwork(id: string): RequestParams<void, DeleteOracleNetworkErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/oracle/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /oracle
     * Creates an oracle network
     * @param body Body parameters
     * @returns The request parameters
     */
    public static CreateOracleNetwork(body: OracleNetworkCreateBody): RequestParams<OracleNetworkCreateResponse, CreateOracleNetworkErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/oracle`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(400, "TOO_MANY_NETWORKS", handler.badRequestTooManyNetworks)
                    .add(400, "INVALID_DESCRIPTION", handler.badRequestInvalidDescription)
                    .add(400, "INVALID_NAME", handler.badRequestInvalidName)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /oracle/{id}/details
     * Changes oracle network details
     * @param id Network ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static ModifyOracleNetworkDetails(id: string, body: OracleNetworkDetailsChangeBody): RequestParams<void, ModifyOracleNetworkDetailsErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/oracle/${encodeURIComponent(id)}/details`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "INVALID_DESCRIPTION", handler.badRequestInvalidDescription)
                    .add(400, "INVALID_NAME", handler.badRequestInvalidName)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /oracle/{id}/config
     * Sets oracle network configuration
     * @param id Network ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static SetOracleNetworkConfiguration(id: string, body: OracleConfiguration): RequestParams<void, SetOracleNetworkConfigurationErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/oracle/${encodeURIComponent(id)}/config`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "CONFIG_TOO_BIG", handler.badRequestConfigTooBig)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /oracle/{id}/secret
     * Re-creates authentication secrets
     * Note: This will require re-configuration of the nodes
     * @param id Network ID
     * @returns The request parameters
     */
    public static ResetSecrets(id: string): RequestParams<void, ResetSecretsErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/oracle/${encodeURIComponent(id)}/secret`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for GetOracleNetwork
 */
export type GetOracleNetworkErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for DeleteOracleNetwork
 */
export type DeleteOracleNetworkErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for CreateOracleNetwork
 */
export type CreateOracleNetworkErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Invalid name
     */
    badRequestInvalidName: () => void;

    /**
     * Invalid description
     */
    badRequestInvalidDescription: () => void;

    /**
     * You reached the limit of networks for your account
     */
    badRequestTooManyNetworks: () => void;
};

/**
 * Error handler for ModifyOracleNetworkDetails
 */
export type ModifyOracleNetworkDetailsErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Invalid name
     */
    badRequestInvalidName: () => void;

    /**
     * Invalid description
     */
    badRequestInvalidDescription: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for SetOracleNetworkConfiguration
 */
export type SetOracleNetworkConfigurationErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * Configuration size too big
     */
    badRequestConfigTooBig: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

/**
 * Error handler for ResetSecrets
 */
export type ResetSecretsErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

