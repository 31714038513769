<template>
    <div class="top-bar" tabindex="-1">
        <div class="top-bar-logo-td">
            <div class="top-bar-icon-menu">
                <i class="fa fa-bars" @click="toggleMenu"></i>
            </div>
            <div class="top-bar-navigation-td">
                <a href="javascript:;" class="top-bar-button-navigation-home">{{ setLabelsMenu }}</a>
            </div>
        </div>

        <div class="top-bar-user-td" v-if="loggedIn">
            <!-- <span class="top-bar-hello-message">{{ $t("Hello, $USER!").replace("$USER", renderName(profileName)) }}</span> -->
            <button
                type="button"
                class="top-bar-button settings-button search-top-bar-button"
                :title="$t('Search')"
                @click="openNetworksDropdrown"
            >
                <i class="fa-solid fa-magnifying-glass"></i>
            </button>
            <button type="button" class="top-bar-button top-bar-user-button" :title="$t('User settings')" @click="openUserSettings">
                <div v-if="profileImage" class="image-container">
                    <img class="logo-image" :src="profileImage" />
                </div>
                <img v-else class="logo" src="/svg/avatar.svg" />
            </button>
        </div>

        <div class="top-bar-user-td" v-if="!loggedIn">
            <button type="button" class="top-bar-button" :title="$t('Select your language')" @click="selectLanguage">
                <i class="fas fa-language"></i>
            </button>
            <button type="button" class="top-bar-button" :title="$t('Change theme')" @click="invertTheme">
                <i v-if="theme === 'dark'" class="fas fa-sun"></i>
                <i v-else class="fas fa-moon"></i>
            </button>
            <button type="button" @click="login" class="btn btn-primary btn-top-bar-login">
                <i class="fas fa-sign-in"></i> {{ $t("Login") }}
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { ColorThemeName, getTheme, setTheme } from "@/control/app-preferences";
import { AppEvents } from "@/control/app-events";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { CurrentNetworkController } from "@/control/current-network";
import { CurrentNodeController } from "@/control/current-node";

export default defineComponent({
    components: {},
    name: "TopBar",
    emits: ["toggle-menu", "openModal"],
    data: function () {
        return {
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            theme: getTheme(),
            profileName: AuthController.ProfileName || AuthController.Username || "",

            networkName: CurrentNetworkController.NetworkData ? CurrentNetworkController.NetworkData.name : "",
            nodeName: CurrentNodeController.NodeData ? CurrentNodeController.NodeData.name : "",
        };
    },
    computed: {
        setLabelsMenu: function () {
            if (this.$route.name) {
                switch (this.$route.name) {
                    case "home":
                        return this.$t("Dashboard");
                    case "add-oracle":
                        return this.$t("Register oracle network");
                    case "oracles":
                        return this.$t("Oracles");
                    case "oracle":
                        return this.$t("Oracles") + (this.networkName ? " > " + this.networkName : "");
                    case "my-oracles":
                        return this.$t("My Oracles");
                    case "security":
                        return this.$t("Security");
                    case "smart-contracts":
                        return this.$t("Oracle smart contracts");
                    case "data-analysis":
                        return this.$t("Data Analysis");
                    case "containers":
                        return this.$t("Containers");
                    case "logs":
                        return this.$t("Logs");
                    case "help":
                        return this.$t("Support & Help Center");
                    case "node-events":
                        return this.$t("Oracles") + (this.networkName ? " > " + this.networkName : "") + " > " + this.$t("Events");
                    case "add-node":
                        return this.$t("Oracles") + (this.networkName ? " > " + this.networkName : "") + " > " + this.$t("Add node");
                    case "profile":
                        return this.$t("My Profile");
                    case "account-settings":
                        return this.$t("Account Settings");
                    case "admin":
                        return this.$t("Administration and moderation");
                    case "reset-tokens":
                        return this.$t("Oracles") + (this.networkName ? " > " + this.networkName : "") + " > " + this.$t("Reset tokens");
                    case "delete-oracle-network":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Delete Oracle Network")
                        );
                    case "change-password":
                        return this.$t("Oracles") + (this.networkName ? " > " + this.networkName : "") + " > " + this.$t("Change Password");
                    case "configure-network":
                        return (
                            this.$t("Oracles") + (this.networkName ? " > " + this.networkName : "") + " > " + this.$t("Configure Network")
                        );
                    case "node-view":
                        return this.$t("Node View");
                    case "oracle-node":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Nodes") +
                            (this.nodeName ? " > " + this.nodeName : "")
                        );
                    case "oracle-node-events":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Nodes") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Events")
                        );
                    case "re-generate-token-node":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Nodes") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Refresh token")
                        );
                    case "configure-network-block-chains":
                    case "configure-network-blockchain":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Blockchains")
                        );
                    case "configure-network-apis":
                    case "configure-network-api":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("APIs")
                        );
                    case "configure-network-wallets":
                    case "configure-network-wallet":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Wallets")
                        );
                    case "configure-network-mqtt":
                    case "configure-network-mqtts":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("MQTT")
                        );
                    case "configure-network-collection":
                    case "configure-network-collections":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Collections")
                        );
                    case "configure-network-tasks":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Tasks")
                        );
                    case "configure-network-task-variable":
                    case "configure-network-task-variables":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Tasks") +
                            " > " +
                            this.$t("Variables")
                        );
                    case "configure-network-task-trigger":    
                    case "configure-network-task-triggers":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Tasks") +
                            " > " +
                            this.$t("Triggers")
                        );
                    case "configure-network-task":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Tasks")
                        );
                    case "configure-network-task-step":
                    case "configure-network-task-steps":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Tasks") +
                            " > " +
                            this.$t("Steps")
                        );
                    case "configure-network-task-actions":
                    case "configure-network-task-action":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Tasks") +
                            " > " +
                            this.$t("Actions")
                        );
                    case "configure-network-task-collections":    
                    case "configure-network-task-collection":
                        return (
                            this.$t("Oracles") +
                            (this.networkName ? " > " + this.networkName : "") +
                            " > " +
                            this.$t("Configure Network") +
                            (this.nodeName ? " > " + this.nodeName : "") +
                            " > " +
                            this.$t("Tasks") +
                            " > " +
                            this.$t("Collections")
                        );
                    case "variables-expressions-help":
                        return this.$t("Variable expressions documentation");
                    default:
                        return import.meta.env.VITE__PLATFORM_NAME || "DELFOS";
                }
            } else {
                return import.meta.env.VITE__PLATFORM_NAME || "DELFOS";
            }
        },
    },
    methods: {
        toggleMenu: function () {
            AppEvents.Emit("toggle-main-menu");
        },

        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        openNetworksDropdrown: function () {
            this.$emit("openModal", "networks-dropdown");
        },

        selectLanguage: function () {
            this.$emit("openModal", "change-language-modal");
        },

        invertTheme: function () {
            setTheme(this.theme === "dark" ? "light" : "dark");
        },

        login: function () {
            this.$requireLogin();
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
        },

        onThemeChanged: function (t: ColorThemeName) {
            this.theme = t;
        },

        renderName: function (name: string): string {
            return ((name + "").split(" ")[0] + "").split(",")[0] || "???";
        },

        onNetworkNameChanged: function () {
            this.networkName = CurrentNetworkController.NetworkData ? CurrentNetworkController.NetworkData.name : "";
            this.setPageTitle();
        },

        onNodeNameChanged: function () {
            this.nodeName = CurrentNodeController.NodeData ? CurrentNodeController.NodeData.name : "";
            this.setPageTitle();
        },

        setPageTitle: function () {
            this.$setSubTitle(this.setLabelsMenu);
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));
        this.$listenOnAppEvent("current-network-changed", this.onNetworkNameChanged.bind(this));
        this.$listenOnAppEvent("current-node-changed", this.onNodeNameChanged.bind(this));
        this.setPageTitle();
    },
    watch: {
        $route() {
            this.setPageTitle();
        },
    },
});
</script>

<style scoped>
.top-bar-navigation-td a {
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    padding-left: 20px;
    color: var(--top-bar-font-color);
    cursor: default;
}
.top-bar-user-button {
    margin-right: 20px;
}
.dark-theme .top-bar-user-button .logo {
    filter: brightness(0) invert(1);
}
.search-top-bar-button {
    color: var(--top-bar-button-color);
}
.top-bar-icon-menu {
    margin-left: 20px;
}
.top-bar-icon-menu i {
    font-size: 23px;
    cursor: pointer;
}
.image-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid;
}
.image-container .logo-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
@media (max-width: 639px) {
    .top-bar-button {
        width: 30px;
        height: 41px;
    }
    .top-bar-user-button img {
        width: 30px;
    }
    .top-bar-navigation-td a {
        font-size: 16px;
        padding-left: 15px;
        display: none;
    }
}
</style>
