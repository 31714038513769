<template>
    <MainLayout></MainLayout>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MainLayout from "@/components/layout/MainLayout.vue";
import { EVENT_NAME_LOADED_LOCALE, i18nData } from "./i18n";

export default defineComponent({
    name: "App",
    components: {
        MainLayout,
    },
    data: function () {
        return {};
    },
    methods: {
        onLoadedLocale: function (locale: string) {
            this.$updateLocale(locale);
        },
    },
    mounted: function () {
        this.$listenOnAppEvent(EVENT_NAME_LOADED_LOCALE, this.onLoadedLocale.bind(this));

        if (i18nData.locale) {
            this.$updateLocale(i18nData.locale);
        }
    },
});
</script>

<style>
/* Common style */

@import "@/style/main.css";

@import "@/style/theme.css";

@import "@/style/forms.css";

@import "@/style/tables.css";

@import "@/style/modals.css";

@import "@/style/scroll-bar.css";

/* Layout */

@import "@/style/layout/main-layout.css";

@import "@/style/layout/loader.css";

@import "@/style/layout/top-bar.css";

@import "@/style/layout/account-menu.css";

@import "@/style/layout/page.css";

@import "@/style/layout/side-bar.css";

@import "@/style/layout/snack-bar.css";

table{
    width: 100%;
}

.top-btn{
    padding-left: 0;
}
.side-bar{
    z-index: 300;
    transition: box-shadow .3s .15s, transform .3s, margin-left .3s, margin-right .3s, width .3s, z-index 0s ease .3s, -webkit-box-shadow .3s .15s, -webkit-transform .3s;
}
.hide-menu .side-bar{
    margin-left: -340px;
}
.hide-menu .top-bar, .hide-menu .page-content{
    left: 0;
    right: 0;
    width: 100%;
}
.sidebar-backdrop{
    display: none;
}
.btn.btn-primary, a.btn.btn-primary, a.btn.btn-primary:visited, .btn.btn-primary:not(:disabled):hover {
    background: var(--blue-light-color);
    box-shadow: none !important;
    white-space: normal;
}
.leaflet-control-attribution{
    display: none;
}
.leaflet-container{
    z-index: 1;
}
.leaflet-container{
    border-radius: 20px;
    background: none !important;
}
.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
    filter: var(--bg-color-map);
}
.leaflet-marker-icon{
    filter: invert(200%) sepia(115%) saturate(35%) hue-rotate(-160deg) brightness(130%) contrast(100%);
    height: 20px !important;
    width: 20px !important;
    object-fit: contain;
    margin-top: -23px !important;
}
path.leaflet-interactive{
    stroke: white;
    stroke-width: 1;
}
.v3dp__heading, .v3dp__heading:hover, .v3dp__heading .v3dp__heading__center:hover, .v3dp__heading button:hover{
    background:var(--side-bar-bg-color) !important;
    color: #fff !important;
}
.v3dp__elements button:not(:disabled):hover span, .v3dp__elements button.selected span{
    background:var(--side-bar-bg-color) !important;
}
.v3dp__elements button span{
    font-size: 15px !important;
}
.node-active-circle {
    color: lime;
}

.node-inactive-circle {
    color: red;
}
@media (max-width: 1140px) {
    .page-content, .top-bar{
        left: 0;
        right: 0;
        width: 100%;
    }
    .sidebar-backdrop{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 299;
        width: 100vw;
        height: 100vh;
        background-color: #000015;
        -webkit-transition: .3s;
        transition: .3s;
        opacity: .5;
        cursor: pointer;
    }
    .sidebar-backdrop.show-backdrop{
        display: block;
    }
    .side-bar{
        z-index: 300 !important;
    }
}

@media (max-width: 639px) {
    .leaflet-container{
        height: 350px !important;
        border-radius: 0;
    }
}
@media (max-width: 600px) {
    .table-search .v3dp__datepicker input{
        width: 100%;
        margin-bottom: 10px;
    }
}
</style>
