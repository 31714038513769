<template>
    <div
        class="modal-container modal-container-corner no-transition networks-dropdown"
        :class="{ hidden: !display }"
        tabindex="-1"
        role="dialog"
        :aria-hidden="!display"
        @click="close"
        @keydown="escapeToClose"
    >
        <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
            <div class="modal-header">
                <div class="modal-title">{{ $t("Networks") }}</div>
                <button type="button" class="modal-close-btn" :title="$t('Close')" @click="close">
                    <i class="fas fa-times"></i>
                </button>
            </div>

            <div class="modal-body no-padding">
                <div class="input-search-container">
                    <input
                        type="text"
                        class="auto-focus"
                        v-model="searchQuery"
                        @input="onQueryChange"
                        @change="performSearch"
                        :placeholder="$t('SearchByNameAndDescription')"
                    />
                </div>
                <div class="networks-container">
                    <div class="networks">
                        <div class="networks-body">
                            <div v-for="network in filteredOracles" :key="network.id" class="network-item">
                                <router-link :to="{ name: 'oracle', params: { id: network.id } }" @click="close">
                                    <h3>{{ network.name }}</h3>
                                    <p>{{ network.description }}</p>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="filteredOracles.length === 0 && !loading" class="modal-networks-empty">
                    {{ $t("No networks found") }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useVModel } from "../../utils/v-model";
import { getUniqueStringId } from "@/utils/unique-id";
import { ApiOracleList } from "@/api/oracle-config/api-group-oracle-list";
import { Request } from "@asanrom/request-browser";
import { Timeouts } from "@/utils/timeout";
import { OracleNetworkItem } from "@/api/oracle-config/definitions";

const LIMIT_RESULTS = 5;
const PAGE_LIMIT = 100;

export default defineComponent({
    name: "NetworksDropdown",
    components: {},
    emits: ["update:display", "openModal"],
    props: {
        display: Boolean,
    },
    setup(props) {
        return {
            displayStatus: useVModel(props, "display"),
            loadRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            loading: true,
            searchQuery: "",
            earliestDate: null as number,
            filteredOracles: [] as OracleNetworkItem[],
        };
    },
    methods: {
        onQueryChange: function () {
            Timeouts.Set(this.loadRequestId, 200, this.performSearch.bind(this));
        },

        performSearch: function () {
            this.earliestDate = null;
            this.filteredOracles = [];
            this.load();
        },

        load: function () {
            Timeouts.Abort(this.loadRequestId);
            Request.Abort(this.loadRequestId);

            const limit = this.searchQuery ? PAGE_LIMIT : LIMIT_RESULTS;

            this.loading = true;

            Request.Pending(this.loadRequestId, ApiOracleList.GetOracleNetworks({ earliest_date: this.earliestDate, limit: limit }))
                .onSuccess((response) => {
                    this.loading = false;
                    if (response.length > 0) {
                        this.earliestDate = response[response.length - 1].lastModified;
                    }
                    for (const oracle of response) {
                        if (this.filteredOracles.length >= LIMIT_RESULTS) {
                            break;
                        }
                        if (
                            !this.searchQuery ||
                            oracle.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
                            oracle.description.toLowerCase().includes(this.searchQuery.toLowerCase())
                        ) {
                            this.filteredOracles.push(oracle);
                        }
                    }
                    if (response.length >= limit && this.filteredOracles.length < LIMIT_RESULTS) {
                        // Load more
                        this.load();
                    }
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.$requireLogin();
                        },
                        temporalError: () => {
                            Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    Timeouts.Set(this.loadRequestId, 1500, this.load.bind(this));
                });
        },

        open: function () {
            this.displayStatus = true;
        },

        close: function () {
            this.displayStatus = false;
        },

        escapeToClose: function (event) {
            if (event.key === "Escape") {
                this.close();
            }
        },

        stopPropagationEvent: function (e) {
            e.stopPropagation();
        },

        clickOnEnter: function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                event.target.click();
            }
        },

        goBack: function () {
            this.close();
        },
    },
    mounted: function () {
        this.$autoFocus();
        this.performSearch();
    },
    beforeUnmount: function () {
        Timeouts.Abort(this.loadRequestId);
        Request.Abort(this.loadRequestId);
    },
});
</script>

<style scoped>
.networks-dropdown .modal-header {
    border-color: var(--border-dropdown-color);
}
.networks-dropdown .input-search-container {
    padding: 20px 15px;
}
.networks-dropdown .input-search-container input {
    height: 34px;
    width: 100%;
    border-radius: 7px;
    border: 1px solid;
    padding: 10px;
}
.networks-dropdown .networks-body .network-item {
    padding: 0px 15px;
    border-top: 1px solid;
    border-bottom: 1px solid;
}
.networks-dropdown .networks-body .network-item a {
    text-decoration: none;
    color: var(--page-vertical-menu-color);
}
.networks-dropdown .networks-body .network-item h3 {
    font-size: 17px;
}
.networks-dropdown .networks-body .network-item p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: calc(1.2em * 2);
    font-size: 15px;
}
.networks-dropdown .networks-body .network-item h3,
.networks-dropdown .networks-body .network-item p {
    margin: 5px 0;
}
.networks-dropdown .modal-networks-empty {
    padding: 10px 15px;
    border-top: 1px solid;
}
.networks-dropdown .networks-container {
    max-height: 400px;
    overflow-y: auto;
}
@media (max-width: 639px) {
    .networks-dropdown .modal-title {
        font-size: 21px;
    }
    .networks-dropdown .networks-container {
        max-height: 300px;
    }
}
</style>
