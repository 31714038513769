// API bindings: fast_access (Auto generated)

"use strict";

import { RequestErrorHandler, RequestParams, CommonAuthenticatedErrorHandler } from "@asanrom/request-browser";
import { getApiUrl } from "./utils";
import { FastAccessNetworkItem, SetFastAccessOrderBody, FastAccessNetworksSetOrderBody } from "./definitions";

export class ApiFastAccess {
    /**
     * Method: GET
     * Path: /fast_access/networks
     * Gets fast access network list
     * @returns The request parameters
     */
    public static GetNetworks(): RequestParams<FastAccessNetworkItem[], CommonAuthenticatedErrorHandler> {
        return {
            method: "GET",
            url: getApiUrl(`/fast_access/networks`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /fast_access/networks/{id}
     * Adds network to the fast access
     * @param id Network ID
     * @param body Body parameters
     * @returns The request parameters
     */
    public static AddNetwork(id: string, body: SetFastAccessOrderBody): RequestParams<void, AddNetworkErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/fast_access/networks/${encodeURIComponent(id)}`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(404, "*", handler.notFound)
                    .add(400, "INVALID_ORDER", handler.badRequestInvalidOrder)
                    .add(400, "TOO_MANY_ENTRIES", handler.badRequestTooManyEntries)
                    .add(400, "*", handler.badRequest)
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: DELETE
     * Path: /fast_access/networks/{id}
     * Deletes network for fast access list
     * @param id Network ID
     * @returns The request parameters
     */
    public static RemoveNetwork(id: string): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "DELETE",
            url: getApiUrl(`/fast_access/networks/${encodeURIComponent(id)}`),
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }

    /**
     * Method: POST
     * Path: /fast_access/order
     * Sets order of fast access list
     * @param body Body parameters
     * @returns The request parameters
     */
    public static SetOrder(body: FastAccessNetworksSetOrderBody): RequestParams<void, CommonAuthenticatedErrorHandler> {
        return {
            method: "POST",
            url: getApiUrl(`/fast_access/order`),
            json: body,
            handleError: (err, handler) => {
                new RequestErrorHandler()
                    .add(401, "*", handler.unauthorized)
                    .add(500, "*", "serverError" in handler ? handler.serverError : handler.temporalError)
                    .add("*", "*", "networkError" in handler ? handler.networkError : handler.temporalError)
                    .handle(err);
            },
        };
    }
}

/**
 * Error handler for AddNetwork
 */
export type AddNetworkErrorHandler = CommonAuthenticatedErrorHandler & {
    /**
     * General handler for status = 400
     */
    badRequest: () => void;

    /**
     * You already have too many entries in fast access (max 256)
     */
    badRequestTooManyEntries: () => void;

    /**
     * Invalid order value provided
     */
    badRequestInvalidOrder: () => void;

    /**
     * General handler for status = 404
     */
    notFound: () => void;
};

