// API binding utilities

"use strict";

/**
 * API prefix
 */
export const API_PREFIX = "./api/v1";

/**
 * Gets full API URL from path
 * @param path The path
 * @returns The full API URL
 */
export function getApiUrl(path: string): string {
    const base = new URL(import.meta.env.VITE___API_USERS_SYSTEM || "http://localhost", location.protocol + "//" + location.host).toString();
    return new URL(API_PREFIX + path, base).toString();
}

export * from "@/utils/url";
