// Routes

"use strict";

import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import { Timeouts } from "./utils/timeout";
import { AppEvents } from "./control/app-events";

// Router
// https://router.vuejs.org/guide/#javascript

// List of forbidden routes:
//
//    - /srv/ - Reserved for services

const routes: (RouteRecordRaw & {
    meta?: {
        /**
         * Set it to true for sticky sidebar
         */
        sidebarSticky?: boolean;
    };
})[] = [
    /* General / Home */

    {
        name: "home",
        path: "/",
        component: () => import("@/components/routes/HomePage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "oracles",
        path: "/oracles",
        component: () => import("@/components/routes/OraclesPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "oracle",
        path: "/oracle/:id",
        component: () => import("@/components/routes/MyOraclesPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "my-oracles",
        path: "/my-oracles",
        component: () => import("@/components/routes/MyOraclesPage.vue"),
    },

    {
        name: "add-oracle",
        path: "/oracles/add-oracle",
        component: () => import("@/components/routes/my-oracles/AddOraclePage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "reset-tokens",
        path: "/oracle/:id/reset-tokens",
        component: () => import("@/components/routes/my-oracles/ResetTokensPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "delete-oracle-network",
        path: "/oracle/:id/delete-oracle-network",
        component: () => import("@/components/routes/my-oracles/DeleteOracleNetworkPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    
    {
        name: "configure-network-block-chains",
        path: "/oracle/:network/configure-network/blockchains",
        component: () => import("@/components/routes/my-oracles/configure-network/blockchains/BlockChainsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-blockchain",
        path: "/oracle/:network/configure-network/blockchains/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/blockchains/BlockchainPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-apis",
        path: "/oracle/:network/configure-network/apis",
        component: () => import("@/components/routes/my-oracles/configure-network/apis/ApisPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-api",
        path: "/oracle/:network/configure-network/apis/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/apis/ApiPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-wallets",
        path: "/oracle/:network/configure-network/wallets",
        component: () => import("@/components/routes/my-oracles/configure-network/wallets/WalletsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-wallet",
        path: "/oracle/:network/configure-network/wallets/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/wallets/WalletPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-mqtts",
        path: "/oracle/:network/configure-network/mqtts",
        component: () => import("@/components/routes/my-oracles/configure-network/mqtts/MQTTSPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-mqtt",
        path: "/oracle/:network/configure-network/mqtts/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/mqtts/MQTTPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-collections",
        path: "/oracle/:network/configure-network/collections",
        component: () => import("@/components/routes/my-oracles/configure-network/collections/CollectionsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-collection",
        path: "/oracle/:network/configure-network/collections/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/collections/CollectionPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-tasks",
        path: "/oracle/:network/configure-network/tasks",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/TasksPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task",
        path: "/oracle/:network/configure-network/tasks/:task",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/TaskNamePage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-variables",
        path: "/oracle/:network/configure-network/tasks/:task/variables",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/variables/VariablesPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-variable",
        path: "/oracle/:network/configure-network/tasks/:task/variable/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/variables/VariablePage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-collections",
        path: "/oracle/:network/configure-network/tasks/:task/collections",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/collections/TaskCollectionsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-collection",
        path: "/oracle/:network/configure-network/tasks/:task/collection/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/collections/TaskCollectionPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-triggers",
        path: "/oracle/:network/configure-network/tasks/:task/triggers",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/triggers/TriggersPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-trigger",
        path: "/oracle/:network/configure-network/tasks/:task/trigger/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/triggers/TriggerPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-steps",
        path: "/oracle/:network/configure-network/tasks/:task/steps",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/steps/StepsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-step",
        path: "/oracle/:network/configure-network/tasks/:task/step/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/steps/StepPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-actions",
        path: "/oracle/:network/configure-network/tasks/:task/actions",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/actions/ActionsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "configure-network-task-action",
        path: "/oracle/:network/configure-network/tasks/:task/action/:index",
        component: () => import("@/components/routes/my-oracles/configure-network/tasks/actions/ActionPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "add-node",
        path: "/nodes/add-node/:id",
        component: () => import("@/components/routes/node/AddNodePage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "node-events",
        path: "/oracle/:id/events",
        component: () => import("@/components/routes/node/NodeEventsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "oracle-node",
        path: "/oracle/:network/nodes/:id",
        component: () => import("@/components/routes/node/NodeDetailPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "re-generate-token-node",
        path: "/oracle/:network/nodes/:id/refresh-token",
        component: () => import("@/components/routes/node/RefreshNodeTokenPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "oracle-node-events",
        path: "/oracle/:network/nodes/:id/events",
        component: () => import("@/components/routes/node/SingleNodeEventsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },
    
    // {
    //     name: "security",
    //     path: '/security',
    //     component: () => import("@/components/routes/SecurityPage.vue"),
    // },

    // {
    //     name: "help",
    //     path: '/help',
    //     component: () => import("@/components/routes/HelpPage.vue"),
    // },

    {
        name: "variables-expressions-help",
        path: '/variables-expressions-help',
        component: () => import("@/components/routes/VariablesExpressionHelpPage.vue"),
    },

    {
        name: "smart-contracts",
        path: '/smart-contracts',
        component: () => import("@/components/routes/smart-contracts/SmartContractsPage.vue"),
    },

    /* Auth */

    {
        name: "login",
        path: "/login",
        component: () => import("@/components/routes/auth/LoginPage.vue"),
    },

    {
        name: "tfa-login",
        path: "/login/tfa",
        component: () => import("@/components/routes/auth/TwoFactorLoginPage.vue"),
    },

    {
        name: "signup",
        path: "/signup",
        component: () => import("@/components/routes/auth/SignupPage.vue"),
    },
    {
        name: "signup-success",
        path: "/signup/success",
        component: () => import("@/components/routes/auth/SignupSuccessPage.vue"),
    },

    {
        name: "forgot-password",
        path: "/password/forgot",
        component: () => import("@/components/routes/auth/ForgotPasswordPage.vue"),
    },
    {
        name: "reset-password",
        path: "/password/reset/:uid/:token",
        component: () => import("@/components/routes/auth/ResetPasswordPage.vue"),
    },

    {
        name: "verify-email",
        path: "/email/verify/:uid/:token",
        component: () => import("@/components/routes/auth/EmailVerifyPage.vue"),
    },

    {
        name: "tp-login",
        path: "/login/tp/:service",
        component: () => import("@/components/routes/auth/ThirdPartyLogin.vue"),
    },

    {
        name: "tp-signup",
        path: "/signup/tp",
        component: () => import("@/components/routes/auth/ThirdPartySignupPage.vue"),
    },

    /* Profile */

    {
        name: "profile",
        path: "/user/:username",
        component: () => import("@/components/routes/profile/ProfilePage.vue"),
    },

    /* Account */

    {
        name: "account-settings",
        path: "/account-settings",
        component: () => import("@/components/routes/account/AccountSettingsPage.vue"),
    },

    /* Admin */

    {
        name: "admin",
        path: "/admin",
        component: () => import("@/components/routes/admin/AdministrationPage.vue"),
    },

    {
        name: "admin-user",
        path: "/admin/users/:id",
        component: () => import("@/components/routes/admin/UserPage.vue"),
    },

    /* Default */

    {
        path: "/:catchAll(.*)",
        component: () => import("@/components/routes/NotFoundPage.vue"),
    },
];

export function makeApplicationRouter(): Router {
    const router = createRouter({
        // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
        history: createWebHistory(),
        routes, // short for `routes: routes`
    });

    router.beforeEach(() => {
        Timeouts.Set("router-load-state", 300, () => {
            AppEvents.Emit("router-load-state-change", true);
        });
    });

    router.afterEach(() => {
        Timeouts.Abort("router-load-state");
        AppEvents.Emit("router-load-state-change", false);
    });

    return router;
}
